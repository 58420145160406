import { graphql } from 'gatsby'
import React from 'react'
import DiagnosticGuidesPage, {
  DiagnosticGuideItem,
  DiagnosticGuideKind
} from '../component/diagnostic-guides-page.component'
import IndexLayout from 'site/src/components/org.layout/layout.component'
import { CosmicjsInformacoesEstaticasMetadataSeo, CosmicjsEspecialidadesEdge } from 'site/src/data/graphql/graphql-types'
import { PageProps } from 'site/src/utils/local-types'

export interface DiagnosticManualTemplateProps {
  list: DiagnosticGuideItem[]
  specialities: {
    edges: CosmicjsEspecialidadesEdge[]
  }
}

class DiagnosticManualTemplate extends React.Component<PageProps<DiagnosticManualTemplateProps>> {
  render() {
    const staticInfoSeo = this.props.data.cosmicjsInformacoesEstaticas.metadata.seo as CosmicjsInformacoesEstaticasMetadataSeo
    return (
      <IndexLayout location={this.props.location}>
        <DiagnosticGuidesPage
          list={this.props.pageContext.list}
          selectedPage={DiagnosticGuideKind.diagnosticManual}
          location={this.props.location}
          siteUrl={this.props.data.site.siteMetadata.siteUrl}
          seoData={{
            title: staticInfoSeo.titulo,
            description: staticInfoSeo.descricao,
            imgAlt: staticInfoSeo.imagemAlternativa,
            imgSrc: staticInfoSeo.imagem.url
          }}
          specialities={this.props.pageContext.specialities}
        />
      </IndexLayout>
    )
  }
}

export default DiagnosticManualTemplate

export const query = graphql`
  query DiagnosticManualList {
    cosmicjsInformacoesEstaticas(slug: { eq: "manuais-diagnosticos" }) {
      metadata {
        seo {
          titulo
          descricao
          imagemAlternativa
          imagem {
            url
          }
        }
      }
    }
    site {
      ...SiteUrl
    }
  }
`
